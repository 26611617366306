import { createApp } from "vue";
//import { Field, Form } from "vee-validate";
import App from "./App.vue";
import router from "./router";
import * as dayjs from "dayjs";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
//import 'bootstrap-icons';

//new stuff
import store from "./_store";

//import store from './store'

let app = createApp(App);

// General
app.config.globalProperties.CONVENTION_YEAR = "2025";

// Registration
app.config.globalProperties.FIRST_DAY_OF_CONVENTION = "2025-08-01";
app.config.globalProperties.LAST_DAY_OF_CONVENTION = "2025-08-03";
app.config.globalProperties.TWENTYONE_CUTOFF = "2004-07-31";
app.config.globalProperties.EIGHTEEN_CUTOFF = "2007-08-01";
app.config.globalProperties.SIXTEEN_CUTOFF = "2009-08-01";
app.config.globalProperties.THIRTEEN_CUTOFF = "2012-08-01";
app.config.globalProperties.SINGLEDAY_REGISTRATION_PRICE = 40.0;
app.config.globalProperties.TWODAY_REGISTRATION_PRICE = 70.0;
app.config.globalProperties.BASIC_REGISTRATION_PRICE = 85.0;
app.config.globalProperties.ULTIMATE_REGISTRATION_PRICE = 170.0;
app.config.globalProperties.ALPHA_REGISTRATION_PRICE = 320.0;
app.config.globalProperties.EB_BASIC_REGISTRATION_PRICE = 75.0;
app.config.globalProperties.EB_ULTIMATE_REGISTRATION_PRICE = 160.0;
app.config.globalProperties.EB_ALPHA_REGISTRATION_PRICE = 320.0;
app.config.globalProperties.EB_CUTOFF_DATE = "2025-01-24";
app.config.globalProperties.LAGOON_PRICE = 82;
app.config.globalProperties.LAGOON_FULL_PRICE = 108.0;
app.config.globalProperties.LAGOON_DATE = "2025-07-31";
app.config.globalProperties.PROCESSING_FEES = 2.0;
app.config.globalProperties.VOLUNTEER_OPEN = "January";

// Alpha Info
app.config.globalProperties.ALPHA_DINNER = "Saturday, July 20"; //not finalized
app.config.globalProperties.ALPHA_DINNER_TIME = "7:00 PM"; //not finalized
app.config.globalProperties.ALPHA_DINNER_PRICE = 55;
app.config.globalProperties.AD_DEADLINE = "July 5"; //not finalized
app.config.globalProperties.AD_EXTRATICKET_DEADLINE = "2024-07-20"; //not finalized

// Dealer Apps
app.config.globalProperties.SINGLE_TABLE_PRICE = 80.0;
app.config.globalProperties.DOUBLE_TABLE_PRICE = 160.0;
app.config.globalProperties.DEALER_CLOSING_DATE = "2025-02-15";

// Hotel
app.config.globalProperties.HOTEL_LOTTERY_CLOSING = "2024-02-19"; //not finalized

// Charity
app.config.globalProperties.CHARITY_OPEN = "June"; //not finalized
app.config.globalProperties.CHARITY_APP_CLOSE = "Saturday, July 20"; //not finalized

// Dance Competition
app.config.globalProperties.DANCE_APP_OPEN = "May"; //not finalized
app.config.globalProperties.DANCE_APP_CLOSE = "Friday, June 21"; //not finalized
app.config.globalProperties.DANCE_APP_DETERMINATION = "Monday, June 24"; //not finalized

// Music Festival
app.config.globalProperties.FESTIVAL_APP_OPEN = "February"; //not finalized
app.config.globalProperties.FESTIVAL_APP_CLOSE = "Friday, May 24"; //not finalized
app.config.globalProperties.FESTIVAL_APP_DETERMINATION = "June 1"; //not finalized

// DJ Apps
app.config.globalProperties.DJ_APP_OPEN = "February"; //not finalized
app.config.globalProperties.DJ_APP_CLOSE = "Saturday, April 20"; //not finalized
app.config.globalProperties.DJ_APP_DETERMINATION = "June 1"; //not finalized

app.use(store).use(router).mount("#app");
