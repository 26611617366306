import { togglzService } from "../_services";
import { getField, updateField } from "vuex-map-fields";

const setState = () => {
  return {
    vrs: {
      loading: false,
      reloading: false,
      submitting: false,
    },
    togglz: {
      registrationOpen: false,
      badgeMailingOpen: false,
      onsiteOpen: false,
      alphaDinnerOpen: false,
      alphaEventsOpen: false,
      dealersOpen: false,
      panelsOpen: false,
      djsOpen: false,
      danceCompOpen: false,
      volunteersOpen: false,
      casinoVolsOpen: false,
      alphasOpen: false,
      upperTiersOpen: false,
      earlyBirdOpen: false,
      earlyBirdClosedAt: null,
      charityOpen: false,
      hotelOpen: false,
      lagoonOpen: false,
      checkinOpen: false,
      casinoOpen: false,
    },
    // RAWR this is for when settings get migrated from main.js into the db
    // conSettings: {
    //   CONVENTION_YEAR: "2000",
    //   FIRST_DAY_OF_CONVENTION: "2000-01-01",
    //   LAST_DAY_OF_CONVENTION: "2000-01-01",
    //   TWENTYONE_CUTOFF: "2000-01-01",
    //   EIGHTEEN_CUTOFF: "2000-01-01",
    //   SIXTEEN_CUTOFF: "2000-01-01",
    //   THIRTEEN_CUTOFF: "2000-01-01",
    //   SINGLEDAY_REGISTRATION_PRICE: "00.0",
    //   TWODAY_REGISTRATION_PRICE: "00.0",
    //   BASIC_REGISTRATION_PRICE: "00.0",
    //   ULTIMATE_REGISTRATION_PRICE: "00.0",
    //   ALPHA_REGISTRATION_PRICE: "00.0",
    //   EB_BASIC_REGISTRATION_PRICE: "00.0",
    //   EB_ULTIMATE_REGISTRATION_PRICE: "00.0",
    //   EB_ALPHA_REGISTRATION_PRICE: "00.0",
    //   EB_CUTOFF_DATE: "2000-01-01",
    //   LAGOON_PRICE: "00.00",
    //   LAGOON_FULL_PRICE: "00.00",
    //   LAGOON_DATE: "2000-01-01",
    //   PROCESSING_FEES: "0.0",
    //   VOLUNTEER_OPEN: "January",
    //   ALPHA_DINNER: "Monday, January 1",
    //   ALPHA_DINNER_TIME: "12:00 AM",
    //   ALPHA_DINNER_PRICE: "50",
    //   AD_DEADLINE: "January 1",
    //   SINGLE_TABLE_PRICE: "00.0",
    //   DOUBLE_TABLE_PRICE: "00.0",
    //   DEALER_CLOSING_DATE: "2000-01-01",
    //   HOTEL_LOTTERY_CLOSING: "2000-01-01",
    //   CHARITY_OPEN: "January",
    //   CHARITY_APP_CLOSE: "Monday, January 1",
    //   DANCE_APP_OPEN: "January",
    //   DANCE_APP_CLOSE: "Monday, January 1",
    //   DANCE_APP_DETERMINATION: "Monday, January 1",
    //   DJ_APP_OPEN: "January",
    //   DJ_APP_CLOSE: "Monday, January 1",
    //   DJ_APP_DETERMINATION: "January 1",
    // }
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getTogglz({ commit, dispatch }) {
    commit("getTogglzRequest");

    togglzService.getTogglz().then(
      (toggle) => commit("getTogglzSuccess", toggle),
      (error) => {
        commit("getTogglzFailure", error);
      }
    );
  },

  reloadTogglz({ commit, dispatch }) {
    commit("reloadTogglzRequest");

    togglzService.getTogglz().then(
      (toggle) => commit("reloadTogglzSuccess", toggle),
      (error) => {
        commit("reloadTogglzFailure", error);
      }
    );
  },

  saveTogglz({ commit, dispatch, state }, toggle) {
    commit("saveTogglzRequest", toggle);

    togglzService.saveTogglz(toggle).then(
      (volApp) => {
        //if the shirt size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        commit("saveTogglzSuccess", volApp);
        let msg = "Toggle updated successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("saveTogglzFailure", error.toString());
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  getConSettings({ commit, dispatch }) {
    commit("getConSettingsRequest");

    conSettingsService.getConSettings().then(
      (conSetting) => commit("getConSettingsSuccess", conSetting),
      (error) => {
        commit("getConSettingsFailure", error);
      }
    );
  },

  reloadConSettings({ commit, dispatch }) {
    commit("reloadConSettingsRequest");

    conSettingsService.getConSettings().then(
      (conSetting) => commit("reloadConSettingsSuccess", conSetting),
      (error) => {
        commit("reloadConSettingsFailure", error);
      }
    );
  },

  saveConSettings({ commit, dispatch, state }, conSetting) {
    commit("saveConSettingsRequest", conSetting);

    conSettingsService.saveConSettings(conSetting).then(
      (volApp) => {
        //if the shirt size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        commit("saveConSettingsSuccess", volApp);
        let msg = "Con Setting updated successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("saveConSettingsFailure", error.toString());
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const getters = {
  getField,
};

const mutations = {
  updateField,
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  getTogglzRequest(state) {
    state.vrs.loading = true;
  },
  getTogglzSuccess(state, toggle) {
    state.togglz = toggle;
    state.vrs.loading = false;
  },
  getTogglzFailure(state, error) {
    state.vrs.loading = false;
  },
  reloadTogglzRequest(state) {
    state.vrs.reloading = true;
  },
  reloadTogglzSuccess(state, toggle) {
    state.togglz = toggle;
    state.vrs.reloading = false;
  },
  reTogglzFailure(state, error) {
    state.vrs.reloading = false;
  },
  saveTogglzRequest(state, toggle) {
    state.vrs.submitting = true;
  },
  saveTogglzSuccess(state, toggle) {
    state.togglz = toggle;
    state.vrs.submitting = false;
  },
  saveTogglzFailure(state, error) {
    state.vrs.submitting = false;
  },

  getConSettingsRequest(state) {
    state.vrs.loading = true;
  },
  getConSettingsSuccess(state, conSetting) {
    state.conSetting = conSetting;
    state.vrs.loading = false;
  },
  getConSettingsFailure(state, error) {
    state.vrs.loading = false;
  },
  reloadConSettingsRequest(state) {
    state.vrs.reloading = true;
  },
  reloadConSettingsSuccess(state, conSetting) {
    state.conSetting = conSetting;
    state.vrs.reloading = false;
  },
  reConSettingsFailure(state, error) {
    state.vrs.reloading = false;
  },
  saveConSettingsRequest(state, conSetting) {
    state.vrs.submitting = true;
  },
  saveConSettingsSuccess(state, conSetting) {
    state.conSetting = conSetting;
    state.vrs.submitting = false;
  },
  saveConSettingsFailure(state, error) {
    state.vrs.submitting = false;
  },
};

export const togglz = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
